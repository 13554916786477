import { setModal } from "@redux/shared/slices/modal";
import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import styles from "./footer.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";

const yearNow = new Date();

export default function ThriftyAdviceFooter({
    textColor,
    bgColor,
}: {
    textColor?: string;
    bgColor?: string;
}): ReactElement {
    const dispatch = useDispatch();
    const { domain } = useDomainContext();
    const footerMenu = [
        {
            title: "Privacy Policy",
            onClick: () =>
                dispatch(setModal({ active: true, content: "privacy" })),
        },
    ];

    return (
        <footer
            style={{
                color: textColor ? textColor : "black",
                backgroundColor: bgColor ? bgColor : "transparent",
            }}
            className={`${styles["footer"]}  text-center lg:px-6 lg:py-0`}
        >
            <div className="max-w-7xl mx-auto">
                <div
                    className={`${
                        styles["inner"]
                    } lg:flex lg:justify-between lg:items-center flex-row-reverse ${
                        !bgColor ? "border-t" : ""
                    } lg:mx-auto px-4 lg:px-0 py-6 pb-32 lg:py-4`}
                >
                    <div className={`footer-menu ${styles["footer-menu"]}`}>
                        <ul className="flex justify-center mb-3 lg:mb-0   ">
                            {footerMenu.map((item, index) => (
                                <li
                                    key={index}
                                    className={`px-2 font-medium lg:font-light text-sm lg:leading-4 border-r last-of-type:border-r-0`}
                                >
                                    <a
                                        className=" cursor-pointer"
                                        onClick={item.onClick}
                                    >
                                        <span>{item.title}</span>
                                    </a>
                                </li>
                            ))}
                            <li
                                className={`px-2 font-medium lg:font-light text-sm lg:leading-4 border-r last-of-type:border-r-0 `}
                            >
                                <a
                                    className="cursor-pointer"
                                    href="/contact-us"
                                >
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="font-light text-sm">
                        <span>
                            <span>©</span> <span>{yearNow.getFullYear()}</span>{" "}
                            <a href={`https://${domain.name}`}>
                                {domain.niceName}
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    );
}
